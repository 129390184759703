.privacyPolicy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacyPolicy h1 {
    font-size: 2.5em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .privacyPolicy h2 {
    font-size: 1.8em;
    color: #2980b9;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .privacyPolicy p {
    margin-bottom: 15px;
  }
  
  .privacyPolicy ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacyPolicy li {
    margin-bottom: 5px;
  }
  
  .privacyPolicy section {
    margin-bottom: 30px;
  }
  
  .privacyPolicy a {
    color: #3498db;
    text-decoration: none;
  }
  
  .privacyPolicy a:hover {
    text-decoration: underline;
  }