:root {
    --primary-color: #4A90E2;
    --secondary-color: #50E3C2;
    --text-color: #333;
    --background-color: #F5F7FA;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1rem 0;
  }
  
  .hero {
    background-color: var(--primary-color);
    color: #fff;
    padding: 4rem 0;
    text-align: center;
  }
  
  .features {
    padding: 4rem 0;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .cta-button {
    background-color: var(--secondary-color);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #3CC7AA;
  }
  .pricing {
    background-color: #fff;
    padding: 4rem 0;
  }
  
  .pricing h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .pricing > p {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .pricing-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  .pricing-card.popular {
    border: 2px solid var(--primary-color);
  }

  .pricing-card .price {
    margin-bottom: 1rem;
  }
  
  .pricing-card .price span.text-3xl {
    color: var(--primary-color);
  }
  
  .pricing-card .price span.text-sm {
    color: #666;
  }
  
  .alert {
    background-color: #e6f3ff;
    border-left: 4px solid var(--primary-color);
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 4px;
  }
  
  .alert-title {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
  }
  
  .alert-description {
    color: #666;
  }

  .cta-section {
    background: linear-gradient(to right, var(--primary-color), #8e44ad);
    color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 3rem;
  }
  
  .cta-button {
    background-color: white;
    color: var(--primary-color);
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-weight: bold;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: var(--primary-color);
  }
  
  @media (max-width: 640px) {
    .cta-section {
      text-align: center;
    }
  }
  
  .popular-tag {
    position: absolute;
    top: 0;
    right: 1rem;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 0.8rem;
  }
  
  .pricing-card h3 {
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .price span {
    font-size: 1rem;
    font-weight: normal;
  }

  .features {
    background-color: #f8f9fa;
    padding: 40px 0;
  }
  
  .features-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .features h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 28px;
    color: #333;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .feature-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  .feature-icon {
    color: #4A90E2;
    margin-bottom: 16px;
  }
  
  .feature-card h3 {
    font-size: 20px;
    margin-bottom: 12px;
    color: #333;
  }
  
  .feature-card p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .feature-grid {
      grid-template-columns: 1fr;
    }
    
    .features-container {
      padding: 0 16px;
    }
  }
  
  .features-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .features-list svg {
    margin-right: 0.5rem;
    color: var(--primary-color);
  }
  
  .select-plan-btn {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .select-plan-btn:hover {
    background-color: #3A7BC8;
  }

  .hero {
    background-color: #f2f2f7;
    padding: 40px 20px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  
  .hero h1 {
    font-size: 32px;
    font-weight: 700;
    color: #1c1c1e;
    margin-bottom: 16px;
  }
  
  .hero .subtitle {
    font-size: 18px;
    color: #3a3a3c;
    margin-bottom: 12px;
    line-height: 1.4;
  }
  
  .hero .tagline {
    font-size: 20px;
    font-weight: 600;
    color: #007aff;
    margin-bottom: 24px;
  }
  
  .features-highlight {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 32px;
  }
  
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feature svg {
    color: #007aff;
    margin-bottom: 8px;
  }
  
  .feature p {
    font-size: 14px;
    color: #3a3a3c;
  }
  
  .app-store-link {
    margin-top: 32px;
  }
  
  .app-store-link img {
    transition: transform 0.3s ease;
  }
  
  .app-store-link img:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 28px;
    }
  
    .hero .subtitle {
      font-size: 16px;
    }
  
    .hero .tagline {
      font-size: 18px;
    }
  
    .features-highlight {
      flex-direction: column;
      gap: 16px;
    }
  }
  
  .features-highlight {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;
  }
  
  .features-highlight .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .features-highlight .feature svg {
    margin-bottom: 0.5rem;
  }
  
  .pricing-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  .pricing-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .prices {
    margin-bottom: 1rem;
  }
  
  .prices p {
    margin: 0.5rem 0;
  }
  
  .features-list {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .features-list svg {
    margin-right: 0.5rem;
  }
  
  .select-plan-btn {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .select-plan-btn:hover {
    background-color: var(--secondary-color);
  }

  .footer {
    background-color: #f2f2f7;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #1c1c1e;
  }
  
  .footer-section p {
    font-size: 14px;
    color: #3a3a3c;
    line-height: 1.4;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  .footer-section ul li a {
    color: #007aff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .social-icons a {
    display: flex;
    align-items: center;
    color: #007aff;
    text-decoration: none;
  }
  
  .social-icons svg {
    margin-right: 8px;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    color: #8e8e93;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
    
    .footer-section {
      margin-bottom: 30px;
    }
  }